$black: #323232;
$red: #D20918;
$white: #fff;

$grey: $black; //#EBEBEB;
$grey1: $black; //#9D9FA0;
$grey2: $black; //#D7D5D6;

$bg-box: $white;

$font-color: $black; //#000;
$link-color: $black; //#000;

// Bootstrap
$icon-font-path: '/assets/fonts/bootstrap/';

$body-bg: $black;
$container-lg: 970px;

// NAVBAR
//$navbar-height: 10px; // TODO V2 = 10px
////$navbar-margin-bottom: 0px; // TODO V2

$nav-link-padding: 0px 2px; // TODO V2 = 0px 0px;

$navbar-default-bg: $white;
$navbar-default-border: #000;

$navbar-default-color: $red;
$navbar-default-link-color: $black;

$navbar-default-brand-color: $body-bg;

$navbar-default-link-hover-bg: transparent;
$navbar-default-link-hover-color: $red;

$navbar-default-link-active-color: $red;
$navbar-default-link-active-bg: transparent;

$navbar-default-toggle-hover-bg: $white;
$navbar-default-toggle-icon-bar-bg: $black;
$navbar-default-toggle-border-color: $black;

$navbar-border-radius: 0px;

//$screen-md: 992px !default;
//$screen-md-min: $screen-md !default;
//$grid-float-breakpoint: $screen-md-min;

//$navbar-padding-horizontal: 5px;
//$navbar-padding-vertical: 0px;

$breadcrumb-padding-vertical: 0px;
$breadcrumb-padding-horizontal: 0px;
$breadcrumb-bg: transparent;
$breadcrumb-color: #aaa;
$breadcrumb-separator: "⟩";

// LINKS

$link-color: $red;
$link-hover-decoration: none;

// HEADINGS

//$headings-color: $red;

$font-size-base: 13px;
$font-size-h1: floor(($font-size-base * 1.5));
$font-size-h2: floor(($font-size-base * 1.45));
$font-size-h3: ceil(($font-size-base * 1.1));
$font-size-h4: ceil(($font-size-base * 1.0));
$font-size-h5: ceil(($font-size-base * 0.9));
$font-size-h5: ceil(($font-size-base * 0.85));

// form

$input-border-radius: 1px;
$input-border-radius-small: 1px;
$form-group-margin-bottom: 10px;

@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";

// cookie approval
//$ka-cookie-bg: #222 !default;
//$ka-cookie-color: #fff !default;
//$ka-cookie-link-color: #31a8f0 !default;
//$ka-cookie-link-color-hover: #b2f7ff !default;
//$ka-cookie-btn-bg: $primary-color;
//$ka-cookie-btn-color: $secondary-color;

@import "../website/site/plugins/cookie/cookie.scss";

//@import "css-debug";

@import "header";
@import "content";
@import "footer";
@import "widget";

@import "alert";
@import "image";
@import "piwik";

@import "templates/gallery";
@import "templates/product";

@import "col-middle";

dl.dl-horizontal {
  //margin: 0;

  dt {
    font-weight: normal;
    float: left;
    width: auto;
    clear: left;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  dd {
    margin-left: 50px;
  }
}

h2 {
  margin-top: 30px;
  //color: $red;
}

p {
  margin-bottom: 20px;
}

.rotate th {

  /* Something you can count on */
  height: 200px;
  white-space: nowrap;

  > div {
    transform: translate(5px, -10px) // Magic Numbers
    rotate(270deg); // 45 is really 360 - 45
    width: 25px;
  }

  //-moz-transform: rotate(-90.0deg); /* FF3.5+ */
  //-o-transform: rotate(-90.0deg); /* Opera 10.5 */
  //-webkit-transform: rotate(-90.0deg); /* Saf3.1+, Chrome */
  //filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083); /* IE6,IE7 */
  //-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)"; /* IE8 */
}

// mail formular
.uniform__potty {
  position: absolute;
  left: -9999px;
}