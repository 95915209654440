.header {
  position: relative;
  background: $bg-box;
  height: 150px;
  margin-top: 15px;
  margin-bottom: 15px;

  .nav > li > a {
    padding-left: 15px;
  }

  // collapsed = XS
  @media (max-width: $screen-xs-max) {
    .logo {
      img {
        @include img-responsive();
        margin: 20px auto;
      }
    }
  }

  // Not Collapsed
  @media (min-width: $screen-sm-min) {

    .navbar-nav > li > a {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .navbar-default {
      background-color: transparent;
      border-color: transparent;
    }

    .navbar {
      position: absolute;
      bottom: 0;
      margin-left: 15px;
      margin-bottom: 20px; // -5px
      min-height: 20px;
    }

    .logo {
      position: absolute;
      right: 40px;
      bottom: 25px;
    }
  }

  /* Small devices (tablets, 768px) */
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .navbar-collapse {
      margin-left: -15px;
    }
  }

  /* Medium devices (desktops, 992px) */
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

  }

  /* Large devices (large desktops, 1200px) */
  @media (min-width: $screen-lg-min) {

  }

  .breadcrumb {
    position: absolute;
    bottom: 0;
    margin-left: 185px; //172
    margin-bottom: 5px;
  }
}