.content {

  @media (min-width: $screen-md-min) {
    padding-top: 10px;
    padding-left: 30px;
  }

  background: $bg-box;

  .headerimage {
    margin-bottom: 20px;
  }

  main {
    padding-bottom: 8px;
  }

}