.widgets {

  margin-top: 38px;

  .widget {

    margin-top: 10px;

    font-size: 8pt;

    h3 {
      background: $black;
      font-size: 10pt;
      text-transform: uppercase;
      font-weight: normal;
      color: $white;
      padding: 10px;
      margin: 0 -15px 0 0;
    }

    p {
      margin-top: 15px;
      margin-right: 15px;
      margin-left: 15px;
      margin-bottom: 10px;
    }

    .rs-logo {
      float: left !important;
      margin: 0 10px 0 0;
      clear: left;
    }

    dl.dl-horizontal {
      margin-top: 15px;
      margin-right: 15px;
      margin-left: 15px;
      margin-bottom: 10px;

      dd {
        margin-left: 40px;
      }
    }
  }
}