.footer {

  line-height: 40px;
  color: $white;
  font-size: 8pt;

  // XS
  @media (max-width: $screen-xs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }

  p {
    display: inline;
    padding: 0;
    margin: 0;
  }

  ul {

    display: inline;

    li {
      display: inline;
      margin-left: 15px;
    }
  }

  a, a:hover {
    color: $white;
  }
}