figure {
  img {
    width: 100%;
  }
}

.image-left {
  margin: 0 0 20px 0;

  // big
  @media (min-width: $screen-sm-min) {
    max-width: 45%;
    float: left !important;
    margin-right: 20px;
    clear: left;
  }

  // small
  @media (max-width: $screen-xs-max) {
    @include img-responsive();
    margin: 20px auto;
  }
}

.image-right {
  margin: 0 0 20px 0;

  @media (min-width: $screen-sm-min) {
    max-width: 45%;
    float: right !important;
    margin-left: 20px;
    clear: right;
  }

  @media (max-width: $screen-xs-max) {
    @include img-responsive();
    margin: 20px auto;
  }
}

.image-center {
  @include img-responsive();
  margin: 20px auto;
}