.products {

  margin-top: 30px;

  h3 {
    //font-size: ceil(($font-size-base * 1.05));
    font-size: ceil(($font-size-base * 0.9));
    margin: 0px;
    background: $black;
    padding: 3px;

    a {
      color: #fff;
      font-weight: 300;
      width: 100%;
    }
  }

  img {
    margin-bottom: 5px;
    margin-top: 2px;
  }
}

ul {
  &.icon {

    list-style: none;
    padding-left: 25px;
    margin-top: -15px;
    margin-bottom: 20px;

    li:before {
      //content: "\e022";
      font-family: 'Glyphicons Halflings';
      font-size: 11px;
      margin-right: 5px;

    }

    .download:before {
      content: "\e022";
    }

    .link:before {
      content: "\e144";
    }

    .contact:before {
      content: "\2709";
    }
  }
}

//&.link {
//
//  list-style: none;
//  padding-left: 25px;
//
//  li:before {
//
//    font-family: 'Glyphicons Halflings';
//    font-size: 11px;
//    margin-right: 5px;
//  }
//}
//
//&.contact {
//
//  list-style: none;
//  padding-left: 25px;
//
//  li:before {
//    content: "\2709";
//    font-family: 'Glyphicons Halflings';
//    font-size: 11px;
//    margin-right: 5px;
//  }
//}